





































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import api from "@/core/utils/api";
import Storage from "@/core/utils/LocalStorage";

@Component({
  components: {},
})
export default class SubscriptionCheckout extends Vue {
  @Getter("profile/getUserName") myName!: string;
  @Action("loadEverything") loadEverything!: () => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: Function;

  roleUpdating = false;

  get name() {
    return this.myName;
  }

  get checkout() {
    const {
      payment_intent,
      payment_intent_client_secret,
      redirect_status,
    } = this.$route.query;

    return {
      payment_intent: payment_intent as string,
      payment_intent_client_secret: payment_intent_client_secret as string,
      // Status of this PaymentIntent, one of `requires_payment_method`, `requires_confirmation`, `requires_action`, `processing`, `requires_capture`, `canceled`, or `succeeded`.
      redirect_status: redirect_status as string,
    };
  }

  updateToken = async () => {
    // Make refresh request
    const refreshingRequest: any = api.post("/api/Account/RefreshJwt", {
      token: Storage.getAccessToken() || "",
      refreshToken: Storage.getRefreshToken() || "",
    });

    // Await refresh, get tokens, set tokens
    const { accessToken, refreshToken } = await refreshingRequest;
    Storage.setAccessToken(accessToken);
    Storage.setRefreshToken(refreshToken);
  };

  checkRolesInterval: any;

  created() {
    if (
      this.checkout.redirect_status === "succeeded" &&
      this.name &&
      !this.roleUpdating &&
      !this.checkRolesInterval
    ) {
      this.displaySnackbar(this.$t("snack.stripe.subscribing"));
      this.roleUpdating = true;
      // Use timeout to prevent clash with notification init on hard reload
      const timeout = setTimeout(async () => {
        this.checkRolesInterval = setInterval(async () => {
          const rolesRequest: any = await api.get("/api/Users/GetRolesByToken");
          // Keep checking until subscriber-free role is removed and new role assigned
          if (
            !rolesRequest.roles.some(
              (r: any) => r.name === "subscriber-free",
            ) &&
            rolesRequest.roles.length
          ) {
            clearInterval(this.checkRolesInterval);
            this.checkRolesInterval = undefined;
            await this.updateToken();
            this.roleUpdating = false;
            await this.loadEverything();
            clearTimeout(timeout);
          }
        }, 2000);
      }, 1000);
    }
  }

  beforeDestroy() {
    clearInterval(this.checkRolesInterval);
    this.checkRolesInterval = undefined;
  }
}
